.BurnButton {
    background-color: #ff442f;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    line-height: 0.5;

}

.BurnButton:hover {
    background-color: #be1300;
    color: white;
}

.StakeButton {
    background-color: #b206b8;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.StakeButton:hover {
    background-color: #8a0c8f;
}