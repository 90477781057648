.Landing {
    align-content: center;
    justify-content: center;
    width: 100vw;
}

.schema-filter {
    align-items: start;
    left: 10vw;
    position: absolute;
}

.Landing-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    margin-top: 5vh;
    align-content: center;
    justify-content: space-around;
}

.Landing-Div-Left,
.Landing-Div-Right {
    padding: 20px;
    width: 100vw;
    height: auto;
    /* Adjust height for responsive behavior */
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    box-sizing: border-box;
    /* Ensures padding doesn't affect the width */
}

.Landing-Div-Right {
    margin-right: 20px;
}

.assets-grid-dashboard {
    display: grid;
    grid-template-columns: repeat(8, auto);
    justify-content: space-around;
    padding: 10px;
    width: 80vw;
    height: 80vh;
    margin-right: 8vw;
    margin-left: 8vw;
    gap: 5px;
    row-gap: 5px;
    overflow-y: auto;
}

.load-more {
    margin: 20px auto;
    display: block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}

.load-more:hover {
    background-color: #0056b3;
}