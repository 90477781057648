.foot {
    position: relative;
    width: 100%;
    height: 6%;
    background-color: #13131d;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    left: 0;
    bottom: 0;
    z-index: 1000;
    border-radius: 0;
    align-content: center;
    border-radius: 10px;
}

.user-info-bals {
    display: grid;
    grid-template-rows: repeat(2, auto);
    max-height: 6%;
}

.user-bals {
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin-left: 5px;
}


.foot-left,
.foot-center,
.foot-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    /* Prevent wrapping for children */
}

.foot-right {
    margin-right: 30px;
}

.foot-right h4,
.foot-right h5 {
    font-size: 0.7rem;
    margin-top: 0px;
    margin-bottom: 0px;
}

.foot-link.selected {
    color: rgb(255, 0, 170);
    /* Highlight selected link */
}

.foot-logo {
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    display: flex;
    /* Use flex for h5 */
    align-items: center;
}

.foot-link {
    margin: 0 15px;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
}

.foot-link:hover {
    text-decoration: underline;
}




.logout-button {
    background-color: #bb5952;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 1rem;
    margin-left: 10px;
    margin-right: 30px;
}

.logout-button:hover {
    background-color: #d32f2f;
}