/* Asset Card Styles */
.asset-card,
.asset-card-small {
    background-color: #34495e;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Smooth transition */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center items horizontally */
    justify-content: space-between;
    /* Space between elements */
    height: 250px;
    width: 8vw;
    /* Fixed height for consistent card sizes */
}

.asset-card-small {
    height: 160px;
    width: 66px;

}

.asset-card:hover {
    background-color: #34495e;
    /* Slight change on hover */
}

.asset-card.selected {
    background-color: rgb(255, 145, 0);
    color: rgb(82, 0, 82);
    /* Orange background when selected */
}

.nft-video {
    width: 100px;
    /* Slightly smaller to fit better */
    height: 200px;
    /* Slightly smaller to fit better */
    object-fit: cover;
    margin: 5px 0;
    /* Adjust margin for better spacing */
}

.nft-video-small {
    width: 70px;
    /* Slightly smaller to fit better */
    height: 140px;
    /* Slightly smaller to fit better */
    object-fit: cover;
    margin: 5px 0;
    /* Adjust margin for better spacing */
}

.asset-card p {
    margin: 5px 0;
    /* Adjust margin for better spacing */

}

/* Specific styles for the mint number and asset name */
.asset-card .mint-number {
    font-weight: bold;
    /* Make mint number bold */
    font-size: 1em;
    /* Increase font size */
    margin-bottom: auto;
    /* Pushes it to the top */
    color: #ff7300;
}

.asset-card .asset-name,
.asset-card-small .asset-name {
    margin-top: auto;
    /* Pushes it to the bottom */
    font-weight: bold;
    /* Optional: Make asset name bold */
    color: #ff7300;

}

.asset-card-small .asset-name {
    font-size: 0.55rem;

}

.asset-card-small .mint-number {
    font-size: 0.8rem;
    margin-top: auto;


}

.asset-card-small.selected,
.asset-card-small.selected .mint-number,
.asset-card-small.selected .asset-name {
    background-color: rgb(236, 134, 0);
    color: rgb(82, 0, 82);
    /* Orange background when selected */
}