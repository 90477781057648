.halloween {
    color: #f306d3;
    margin-right: 5vw;
}

.event-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.event-title p {
    color: white;
}

.LeaderBoard {
    min-height: 100vh;
    max-width: 100vw;
    padding-top: 40px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.leaderboard-container {
    background-color: #0b0d1d;
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, auto);
    width: 100%;
    min-height: 50vh;
    align-items: center;
    vertical-align: top;
    padding: 20px;
    border-radius: 10px;
    flex-grow: 1;
}

.leaderboard-layout {

    display: flex;
    justify-content: space-between;
    /* This will space out the two columns */
    width: 100%;
    max-width: 1200px;
    /* Adjust as needed */
}

/* Individual Entry Styles */
.leaderboard-entry {
    padding: 12px 20px;
    background-color: #56188f;
    border-radius: 10px;
    margin-bottom: 10px;
    color: #05da10;
    transition: background-color 0.3s;
}

.leaderboard-entry:hover {
    background-color: #34495e;
}

.leaderboard {
    border-radius: 10px;
    color: #05da10;
    width: 50%;
    padding: 10px;
    overflow-y: auto;
    flex-grow: 1;
}

.shards-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* This ensures the button will be pushed to the bottom */
    margin-right: 1vw;
}

.shards-grid {
    max-height: 80%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    gap: 5px;
    row-gap: 5px;
    overflow-y: auto;
    /* Ensures the grid occupies the space above the button */
    flex-grow: 1;
}

.button-case {
    display: flex;
    justify-content: space-between;
    /* Align buttons within button-case */
    margin-top: auto;
    /* This pushes the button to the bottom of the shards-section */
    padding-top: 10px;
    /* Optional: add some space between grid and button */
    height: 5vh;
    align-content: center;

}

.load-more-shards,
.load-more {
    color: black;
    cursor: pointer;
    line-height: 0;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.load-more:hover {
    color: white;

    background-color: #003d7e;

}

.select-all {
    background-color: #05da10;
    border-radius: 5px;
    cursor: pointer;
    border: none;


}

.select-all:hover {
    background-color: #06a80e;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    margin-top: 10px;
    padding: 5px 15px;
    color: white;

}