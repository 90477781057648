/* Navbar Styles */
.navbar {
    width: 90%;
    height: 6%;
    /* Keep this height constant */
    background-color: #13131d;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0vh;
    left: 3.2vw;
    z-index: 1000;
    flex-wrap: nowrap;
    border-radius: 10px;
    align-content: center;
    /* Prevent wrapping */
}

.user-info-bals {
    display: grid;
    grid-template-rows: repeat(2, auto);
    max-height: 6%;
}

.user-bals {
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin-left: 5px;
}


.navbar-left,
.navbar-center,
.navbar-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    /* Prevent wrapping for children */
}

.navbar-right {
    margin-right: 30px;
}

.navbar-right h4,
.navbar-right h5 {
    font-size: 0.7rem;
    margin-top: 0px;
    margin-bottom: 0px;
}



.navbar-logo {
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    display: flex;
    /* Use flex for h5 */
    align-items: center;
}

.navbar-link {
    margin: 0 15px;
    color: #fff;
    font-size: 1rem;
    text-decoration: none;

}

.navbar-link-a {
    text-decoration: none;

}

.navbar-link-selected {
    color: rgb(235, 0, 156);
    /* Highlight selected link */
}

.navbar-event,
.navbar-event.selected {
    margin: 0 15px;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
    border-color: rgb(214, 21, 214);
    border-style: solid;
    border-radius: 3px;
}

.navbar-fake {
    margin: 0 15px;
    color: #6b6b6b;
    text-decoration: none;
    font-size: 1rem;
}



.navbar-link:hover {
    text-decoration: underline;
}




.logout-button {
    background-color: #bb5952;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 1rem;
    margin-left: 10px;
    margin-right: 30px;
}

.logout-button:hover {
    background-color: #d32f2f;
}