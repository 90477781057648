/* subbar Styles */
.subbar {
    width: 80%;
    max-height: 6%;
    /* Keep this height constant */
    background-color: #13131d;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    margin-top: 70px;
    margin-left: 8vw;
    z-index: 1000;
    flex-wrap: nowrap;
    border-radius: 10px;
    align-content: center;
    /* Prevent wrapping */
}

.inv-count {
    margin-left: 5vw;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 2vw;
    max-height: 6%;
    padding: 0px;
}

.inv-grid {
    padding: 0px;
    margin: 0;
    row-gap: 0;
}

.inv-grid h4 {

    line-height: 0.001;
}

.user-info-bals {
    display: grid;
    grid-template-rows: repeat(2, auto);
    max-height: 6%;
}

.user-bals {
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin-left: 5px;
}


.subbar-left,
.subbar-center,
.subbar-right {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    max-height: 6%;
    /* Prevent wrapping for children */
}

.subbar-left {
    justify-content: space-around;
}

.subbar-right {
    margin-right: 30px;
}

.subbar-right h4,
.subbar-right h5 {
    font-size: 1rem;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 2vw;
    margin-right: 2vw;
}

.subbar-center h4 {
    font-size: 1.5rem;
    line-height: 0;
}

.subbar-logo {
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    display: flex;
    /* Use flex for h5 */
    align-items: center;
}

.subbar-link {
    margin: 0 15px;
    color: #fff;
    text-decoration: none;
    font-size: 1rem;
}

.subbar-link:hover {
    text-decoration: underline;
}




.logout-button {
    background-color: #bb5952;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    font-size: 1rem;
    margin-left: 6vw;

    margin-right: 30px;
}

.logout-button:hover {
    background-color: #d32f2f;
}